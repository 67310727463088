import React , { useMemo } from "react";
import { Box, Card, CardMedia, CircularProgress, Typography } from "@mui/material";


const ThumbnailGallery = React.memo(function ({ small, selectedPostData, multiple, numAccounts, numPosts, screenShot }) {
    const [loadedImages, setLoadedImages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
  
    React.useEffect(() => {
      const loadImages = async () => {
        setLoading(true);
    
        const imagesLoaded = await Promise.all(
          selectedPostData.map((post) =>
            new Promise((resolve) => {
              const img = new Image();
              img.src = post.data.thumbnail;
    
              img.onload = () => resolve(img);
              img.onerror = () => resolve(null);  // Handle load errors by resolving null
            })
          )
        );
    
        const validImages = imagesLoaded.filter((img) => img !== null);
        
        if (validImages.length !== loadedImages.length) {
          setLoadedImages(validImages);
        }
    
        setLoading(false);
      };
    
      loadImages();
    }, [selectedPostData]);

    const fullWidth = 300;
    const isMobile = window.innerWidth < 600;

    const sortedImages = useMemo(() => {
      return [...loadedImages].splice(0,20).sort(() => Math.random() - 0.5);
    }, [loadedImages]);
  
    const getStyles = (aspectRatio, isMobile, small) => {
      const maxHeight = (aspectRatio >= 1 ? "220px" : "180px"); // Adjust as needed
      const width =
        aspectRatio >= 1
          ? 
          small ? 120 : 
          isMobile
            ? 180
            : 220
          : aspectRatio < 0.56
          ? 
          small ? 80 : 
          isMobile
            ? 90
            : 100
          : 
          small ? 90 : 
          isMobile
          ? 100
          : 120; // Adjust as needed
  
      return { maxHeight, width };
    };
  
    return loading ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress size={20} />
      </Box>
    ) : (
      <Box
        sx={{
          display: loadedImages.length > 0 ? "flex" : "none",
          position: "relative",
          // flexDirection: "column",
          height: (small || isMobile) ? 160 : 220,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          
          mt: loadedImages.length > 1 ? 2 : 0,
          mb: loadedImages.length > 1 ? 2 : 0,
          "& .MuiCard-root": {
            //   maxWidth: '220px', // Adjust the maximum width of the card
            //   margin: '8px', // Adjust the margin between cards
          },
        }}
      >
        
                {sortedImages.map((img, index) => {
                  const aspectRatio = img.width / img.height;
                  const { width } = getStyles(aspectRatio, isMobile, small);
                  console.log(`Image ${index} loaded into gallery`);
              return (
                <Card
                  elevation={3}
                  sx={{
                    position: "absolute",
                    transform:
                      multiple &&
                      `rotate(${Math.floor(Math.random() * 30) - 15}deg)`,
                    borderRadius: 2,
                    width: width,
                  }}
                >
                  <CardMediaComponent
                    component="img"
                    height="auto"
                    width={width}
                    src={img.src}
                    sx={{ borderRadius: 2 }}
                  />
                </Card>
              );
            })}

      </Box>
    );
  });

  export default ThumbnailGallery;



  function CardMediaComponent({src, width, height, alt, sx}) {

    const [bitmapData, setBitmapData] = React.useState(null);

    return (
 
      <CardMedia
        component="img"
        src={src}
        width={width}
        height={height}
        alt={alt}
        sx={sx}
      />
      
    );
  }