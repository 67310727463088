import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Container,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    Checkbox,
  } from "@mui/material";
  import React, { useCallback, useState } from "react";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import {
    Add,
    AddAPhotoOutlined,
    AddCardOutlined,
    AddOutlined,
    AddPhotoAlternateOutlined,
    AddToPhotosOutlined,
    ArrowBackIos,
    ArrowBackIosNewOutlined,
    ArrowForward,
    ArrowForwardIos,
    AutoAwesome,
    BarChart,
    CampaignOutlined,
    Chat,
    CheckBoxOutlined,
    Clear,
    Close,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DeleteForeverOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FilterList,
    FolderOutlined,
    GroupOutlined,
    Info,
    LabelOutlined,
    OpenInNew,
    PendingActionsOutlined,
    RedoOutlined,
    RefreshOutlined,
    Remove,
    RemoveCircleOutlineOutlined,
    UndoOutlined,
    UploadFileOutlined,
    UploadOutlined,
    VideoFileOutlined,
    WarningOutlined,
    InfoOutlined
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams } from "react-router-dom";
  import { useUser, UserButton } from "@clerk/clerk-react";
  import GroupAutoComplete from "./GroupAutocomplete";

  import Functions from "./Functions";
import GroupView from "./GroupView";
import { formatData, formatFullDate, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
import { getTeamUsageData } from "./Functions"
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { sanitizeInput, containsJSInjection } from "./Helpers";
import { set } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function AddNewModal(props) {

  const { tid, groupMatchType, setGroupMatchType, softRefresh, setProcessingPostsOpen, open, handleClose, type, name, teamData, selectedAssets, refreshData, plan, isMobile
  } = props;
  
    const [teamName, setTeamName] = useState(teamData ? teamData.name : "");
    const [groupName, setGroupName] = useState(teamData && teamData.groups && teamData.groups.length > 0 ? teamData.groups[0].name : "");
    const [groupType, setGroupType] = useState(teamData && teamData.groups ? teamData.groups.length > 0 ? teamData.groups[0].type : "campaign" : "campaign");
    const [links, setLinks] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [separator, setSeparator] = useState("\n");
    const [linksValid, setLinksValid] = useState(true);

    const [usageData, setUsageData] = useState({});
    const [acceptPosts, setAcceptPosts] = useState(false);
    const [groupIds, setGroupIds] = useState([]);
    const [columnHeader, setColumnHeader] = useState("Links");
    const [addToGroup, setAddToGroup] = useState(true);
    const [newCampaignName, setNewCampaignName] = useState("");
    const [newGroupName, setNewGroupName] = useState("");

    const [selectedGroups, setSelectedGroups] = useState(props.selectedGroups);
    const [selectedTags, setSelectedTags] = useState(props.selectedTags);

    React.useEffect(() => {
      setSelectedGroups(props.selectedGroups);
      setSelectedTags(props.selectedTags);
    }, [props.selectedGroups, props.selectedTags]);
  

    const handleModalClose = (e) => {
      
      props.setSelectedGroups(selectedGroups);
      props.setSelectedTags(selectedTags);
      
      handleClose();
      setSection("links");
      setConfirm(false);

    }

    const handleGroupChange = async(event, value) => {
      console.log(event, value);
  
      if(event.key == "Enter"){
         event.preventDefault();
          event.stopPropagation();
      }
  
      let newGroups = JSON.parse(JSON.stringify(value)).filter((option) => typeof option == "string");
  
      if (newGroups.length > 0) {
        for (let i = 0; i < newGroups.length; i++) {
          //create a new campaign with name newGroups[i]
          let name = newGroups[i];
          let newGroup = await Functions.createCampaign(name, tid);
          softRefresh();
        }
        return;
      }
  
      let newValue = JSON.parse(JSON.stringify(value)).filter(
        (option) =>
          (!option.type && !option.campaignId) ||
          (option.type && (option.type == "campaign" || option.type == "group"))
      );
  
      const addedValue = newValue
        .filter((option) => !selectedGroups.some((group) => group.id == option.id))
        .flat();
  
      const removedValue = selectedGroups
        .filter((option) => !newValue.some((group) => group.id == option.id))
        .flat();
    
      let groups = newValue;
  
      if (removedValue.length > 0 && (!removedValue[0].type || removedValue[0].type == "campaign")) {
        groups = groups.filter((group) => !group.campaignId || group.campaignId != removedValue[0].id);
      }
  
      //if added group is a group, remove all campaigns associated with that group
      else if (addedValue.length > 0 && addedValue[0].type && addedValue[0].type == "group") {
        groups = groups.filter((group) => group.id != addedValue[0].campaignId);
      }
  
      //if add group is a campaign, remove all groups associated with that campaign
      else if (addedValue.length > 0 && addedValue[0].type && addedValue[0].type == "campaign") {
        groups = groups.filter((group) => !group.campaignId || group.campaignId != addedValue[0].id);
      }
  
    
  
  
      setSelectedGroups(groups);
      // setDisableGroupChange(true);
      // setTimeout(() => {
      //   setDisableGroupChange(false);
      // }, 1500);
    
    };
  

    const [section, setSection] = useState("links");
    const [confirm, setConfirm] = useState(false);
  
    React.useEffect(() => {

      if (selectedGroups && selectedGroups.length > 0){
        setGroupIds(selectedGroups.map((group) => group.id));

      }
    }
    , [selectedGroups]);


  React.useEffect(() => {
    async function fetchData() {

      let planStart = teamData.plan && teamData.plan.startDate ? new Date(teamData.plan.startDate) :  new Date(new Date().getFullYear(), new Date().getMonth(), 1);

      //let start be the date of this month that the plan started
      let start = new Date(new Date().getFullYear(), 
      
      planStart.getDate() > new Date().getDate() ? new Date().getMonth() - 1 : new Date().getMonth(), 
      
      planStart.getDate()).toISOString();
      
      //let end be today
      let end = new Date( new Date().getFullYear(),  planStart.getDate() > new Date().getDate() ? new Date().getMonth() : new Date().getMonth() + 1 , planStart.getDate() - 1).toISOString();

      let usage = await Functions.getTeamUsageData(teamData.id, start, end);
      setUsageData(usage);

      console.log("usage", usage);
        if (teamData.plan && teamData.plan.type == "subscription" && 
          ((usage.totalComments < teamData.plan.credits)  && usage.postsCompleted < teamData.plan.credits) ||
           (teamData.plan.creditLimit && (usage.totalComments < teamData.plan.creditLimit) && usage.totalPosts < teamData.plan.creditLimit)){

          setAcceptPosts(true);
        }
        if(teamData.plan && teamData.plan.type == "subscription" && teamData.plan.allowedMAAs > 0){
          setAcceptPosts(true);
          
        }
      }
      if(open){
      fetchData();
      }
    }, [open]);
  
    const updateInvitees = (value) => {
      let list = value.split(",");
      setInvitees(list);
    }

    const updateUploadedLinks = (value) => {
      console.log("value", value);


      let newSeparator = separator.trim() == "\n" ? "," : "\n";

      if(!value.includes(separator)){
      setSeparator(newSeparator);
      }
  
      let list = value.split(numLinks > 0 ? separator : newSeparator);
      //remove url params for link in list
    if (list.length > 0){
            list = list.map(async(link, index) => {


                //check if link is a valid url
            let isUrl = isValidSocialMediaLink(link.trim());

            let url = isUrl ? new URL(link) : "";

            if (isUrl && link.includes("watch?v=")) {
                const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
                const match = link.trim().match(regExp);
                if (match && match[2].length === 11) {
                return `https://www.youtube.com/watch?v=${match[2]}`;
                }
              }
            //if link is a tiktok short url ex:   https://vm.tiktok.com/ZM8qQ9Q8b/ or https://www.tiktok.com/t/ZTFRscJPF/
            // fetch the url and get the final url


            
            else{
            return isUrl ? url.origin + url.pathname : link.trim();
            }
            });



            setLinks([...list, ...links]);
            let valid = list.every((link) => link.trim() == "" || isValidSocialMediaLink(link));
            setLinksValid(valid);

        }

 
    };

    const linkExists = (link) => {
      return teamData.posts.some((post) => post.link == link);
    }
  
    const updateLinks = (value) => {
      //find separator
      
      //remove whitespace lines
      let list = value.split(separator);

      let newSeparator = separator == "\n" ? "," : "\n";

      if(!value.includes(separator) || value.includes(newSeparator)){
      setSeparator(newSeparator);
      list = value.split(newSeparator);
      }

      //remove url params for link in list
    if (list.length > 0){
            list = list.map((link, index) => {
                //check if link is a valid url
            let isUrl = isValidSocialMediaLink(link.trim());
            console.log("isUrl", isUrl, link);
            let url = isUrl ? new URL(link) : "";
            console.log("url", url);
            
            if (isUrl && link.includes("watch?v=")) {
                const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
                const match = link.trim().match(regExp);
                if (match && match[2].length === 11) {
                return `https://www.youtube.com/watch?v=${match[2]}`;
                }
              }
            if (isUrl && link.includes("twitter.com")){
              return decodeURIComponent("https://x.com") + decodeURIComponent(url.pathname);
            }
            else{
              return isUrl ? decodeURIComponent(url.origin) + decodeURIComponent(url.pathname) + (url.pathname.endsWith('/') ? '' : '/') : link;            }
            });
        }

        // let trailing = value.slice(-1);

        // console.log("trailing", trailing);

        // if (trailing == separator){
        // list = [...list, `${trailing}`];
        // }


      setLinks(list);
  
      let valid = list.every((link) => link.trim() == "" || (isValidSocialMediaLink(link) && !linkExists(link)));
      setLinksValid(valid);
    }

    const numLinks = links.map((link) => link.trim()).filter((link) => link != ""
&& isValidSocialMediaLink(link) && !linkExists(link)
  ).length;

    const handleLinkDrop = async () => {

      if (numLinks == 0){
        if (newCampaignName !== "" && newCampaignName !== null){
          let campaign = await Functions.createCampaign(newCampaignName, teamData.id);

          if(newGroupName !== "" && newGroupName !== null){
            let group = await Functions.createGroup(newGroupName, teamData.id, campaign.id);
          }
        }


        setLinks([]);
        setSection("links");
        handleClose();
        setNewCampaignName("");
        setNewGroupName("");
        refreshData();
        
        return;
      }

      let newGroupIds = [];
      let campaign = null;

      if (!addToGroup && newCampaignName !== "" && newCampaignName !== null){
        campaign = await Functions.createCampaign(newCampaignName, teamData.id);
        newGroupIds = [campaign.id];
      }

      if(campaign && newGroupName !== "" && newGroupName !== null){
        let group = await Functions.createGroup(newGroupName, teamData.id, campaign.id);
        newGroupIds.push(group.id);
        if(!newGroupIds.includes(campaign.id)){
          newGroupIds.push(campaign.id);
        }
      }


      if (addToGroup && selectedGroups.length == 1 && selectedGroups[0].type == "campaign" && newGroupName !== "" && newGroupName !== null){
        let group = await Functions.createGroup(newGroupName, teamData.id, selectedGroups[0].id);
        newGroupIds.push(group.id);
      }

        let newLinks = await Functions.linkDrop(links, teamData.id, newGroupIds.length > 0 ? newGroupIds : groupIds);
        
        setNewCampaignName("");
        setNewGroupName("");
        setSection("links");
        setLinks([]);
        handleModalClose();
        refreshData();
 

        
    }
      
    const handleSetColumnHeader = (event) => { 
      const { value } = event.target;
    const sanitizedValue = sanitizeInput(value);
    if (containsJSInjection(sanitizedValue)) {
      console.warn('Potential JavaScript injection detected.');
    } else {
      setColumnHeader(sanitizedValue);
    }
  }; 

  const handleSetCampaignName = (event) => { 
    const { value } = event.target;
  const sanitizedValue = sanitizeInput(value);
  if (containsJSInjection(sanitizedValue)) {
    console.warn('Potential JavaScript injection detected.');
  } else {
    setNewCampaignName(sanitizedValue);
  }
}; 

const handleSetGroupName = (event) => {
  const { value } = event.target;
const sanitizedValue = sanitizeInput(value);
if (containsJSInjection(sanitizedValue)) {
  console.warn('Potential JavaScript injection detected.');
} else {
  setNewGroupName(sanitizedValue);
}
};

const handleUpdateLinks = (event) => {
  const { value } = event.target;
const sanitizedValue = sanitizeInput(value);
if (containsJSInjection(sanitizedValue)) {
  console.warn('Potential JavaScript injection detected.');
} else {
  updateLinks(sanitizedValue);
}
}; 
  
    const handleGroupTypeChange = (event) => {
      setGroupType(event.target.value);
    }
  
    const handleTeamNameChange = (event) => {
      setTeamName(event.target.value);
    }
  
    const handleGroupNameChange = (event) => {
      setNewGroupName(event.target.value);
    };

    const handleCreateNewCampaign = async () => {

      let group = await Functions.createCampaign(newCampaignName, teamData.id);
      setNewGroupName("");
      handleClose();
      refreshData();
    }
  
    const handleCreateGroup = async () => {
      // Handle group creation logic here
      console.log('Creating new group:', groupName);
      // Clear input and close modal

      let group = await Functions.createGroup(newGroupName, teamData.id);
      // setSelectedGroups([...selectedGroups, group]);

      setNewGroupName("");

      handleClose();
      refreshData();

    };
  
    const modalStyle = {
      width: "100%",
      borderRadius: 0, // Adjust the border radius as needed
      backgroundColor: 'white',
      // padding: '20px',
      outline: 'none', // Remove the default outline
      position:"fixed",
      left: "50%",
      bottom:0,
      maxHeight: '80%',
      overflowY: 'auto',
      overflowX: 'hidden',
      transform: "translate(-50%, 0)",
    };

    const removeExistingLinks = () => {
      //remove links that already exist in the team
      let newLinks = links.filter((link) => !teamData.posts.some((post) => post.link == link));
      setLinks(newLinks);
    }

    const removeDuplicateLinks = () => {
      //remove duplicate of a link, keep the first instance
      let uniqueLinks = [];
      links.forEach((link) => {
        if (!uniqueLinks.includes(link)) {
          uniqueLinks.push(link);
        }
      });
      setLinks(uniqueLinks);
    };

    const removeInvalidLinks = () => {
      //remove invalid links
      let validLinks = [];
      links.forEach((link) => {
        if (isValidSocialMediaLink(link)) {
          validLinks.push(link);
        }
      });
      setLinks(validLinks);
    };



    const duplicateLinks = links.filter((link, index) => link.trim() != "" && links.indexOf(link) !== index);
    const invalidLinks = links.filter((link) => link.trim() != "" && !isValidSocialMediaLink(link));
    const existingLinks = links.filter((link) => link.trim() != "" && teamData.posts.some((post) => post.link == link));

    const teamPlan = teamData && teamData.plan ? teamData.plan : null;
  
    return (
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            // gap: ".5rem",
            display:"flex",
            // flexDirection:"column",
            // justifyContent:"center",        
          }}
        >
  
          <Box sx={{width:"100%", maxWidth:1000, margin:"auto", display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:2, p:4}}>


          <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                
              
                  flexGrow: 1,
                  gap:2,
                  mb:1,
              
                }}
              >  

              <Typography variant="h6" sx={{fontWeight:700, color: "#4727c3" }}>
                Add New Posts
              </Typography>
              
              {/* <Grid container spacing={2}>
                <Grid item xs={3}>
              <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center", justifyContent:"center", border:"2px solid #999", borderRadius:4, p:1}}>
                <Avatar>
                  1
                </Avatar>
              <Typography variant="body1" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
             Upload Links
            </Typography>
            </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center", justifyContent:"center", border:"2px solid #999", borderRadius:4, p:1}}>
                <Avatar>
                  2
                </Avatar>
              <Typography variant="body1" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
             Review Campaign
            </Typography>
            </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center", justifyContent:"center", border:"2px solid #999", borderRadius:4, p:1}}>
                <Avatar>
                  3
                </Avatar>
              <Typography variant="body1" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
             Confirm
            </Typography>
            </Box>
            </Grid>
            </Grid> */}
          

   
        <Breadcrumbs 
        separator={<ArrowForwardIos sx={{height:20, width:20, fill:"#999"}}/>}
        
        sx={{display:"flex", flexDirection:"row", gap:1, alignItems:"center"}}>
            <Typography variant="body1" color="#333" fontSize={section == "links" ? 20 : 16} fontWeight={section == "links" ? 600 : 500}>
            Upload Links
            </Typography> 
            <Typography variant="body1" color="#333" fontSize={section == "campaign" ? 20 : 16} fontWeight={section == "campaign" ? 600 : 500}>
            Review Campaign
            </Typography>
      
              <Typography variant="body1" color="#333" fontSize={section == "setting" ? 20 : 16} fontWeight={section == "setting" ? 600 : 500}>
            Confirm Settings
            </Typography>
     
        </Breadcrumbs> 

        {/* {numLinks > 0 ?
        <Typography variant="body1" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          Upload Info
        </Typography>
        :
        null
        } */}

        <Box sx={{display:"flex", flexDirection:"row", gap:2, mt:1, alignItems:"center"}}>
                         
                         {numLinks > 0 && (
                           <Chip
                           onClick={() => setSection("links")}
                           sx={{
                             backgroundColor: "#fff",
                             border: `.5px solid #b3b3b3`,
                             borderRadius: 1.5,
                             height: 45
                           }}
                           label={
                             <Box
                               sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 alignItems: "center",
                                 gap:1
                               }}
                             >
                               <Typography variant="caption" color="#4728c4">
                                 <Add    sx={{ color: "#62676a", fontSize: 20 }} />
                               </Typography>
                               <Box
                                 sx={{
                                   display: "flex",
                                   flexDirection: "row",
                                   gap: 0.5,
                                 
                                 }}
                               >
                                   <Chip
                                     label={<>
                         <b> {numLinks}</b> post{numLinks > 1 ? "s" : ""}
                      </>}
                                     size="small"
                                     sx={{
                                       backgroundColor: "#f5f5f5",
                                       color: "#62676a",
                                       fontWeight: 500,
                                       fontSize: 12,
                                       height: 30,
                                     }}
                                   />
                             
                               </Box>
         
                             </Box>
                           }
                         />
                  
                       )}
         
                       
                         {(section == "campaign" || section == "tag" || section == "setting") && selectedGroups && (
                                     <Chip
                                       sx={{
                                         backgroundColor: "#fff",
                                         border: `.5px solid #b3b3b3`,
                                         borderRadius: 1.5,
                                         height: 45
                                       }}
                                       onClick={() => setSection("campaign")}
                                       label={
                                         <Box
                                           sx={{
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "center",
                                             gap:1
                                           }}
                                         >
                                           <Typography variant="caption" color="#4728c4">
                                             <CampaignOutlined    sx={{ color: "#62676a", fontSize: 20 }} />
                                           </Typography>
                                           <Box
                                             sx={{
                                               display: "flex",
                                               flexDirection: "row",
                                               gap: 0.5,
                                             
                                             }}
                                           >
                                             {selectedGroups.slice(0, 2).map((group) => (
                                               <Chip
                                                 key={group.id}
                                                 label={group.name}
                                                 size="small"
                                                 sx={{
                                                   backgroundColor: "#f5f5f5",
                                                   color: "#62676a",
                                                   fontWeight: 500,
                                                   fontSize: 12,
                                                   height: 30,
                                                 }}
                                               />
                                             ))}
                                           </Box>
         
                                           {selectedGroups.length > 2 ? (
                                             <Typography
                                               variant="body2"
                                               color="#555"
                                               fontWeight={600}
                                             >
                                               +{selectedGroups.length - 2}
                                             </Typography>
                                           ) : (
                                             ""
                                           )}
                                            {selectedGroups.length == 0 && (
                                             <Chip
                                             label={<Typography variant="caption" color="#555">
                                               No Campaign
                                             </Typography>}
                                             size="small"
                                             sx={{
                                               backgroundColor: "#f5f5f5",
                                               color: "#62676a",
                                               fontWeight: 500,
                                               fontSize: 12,
                                               height: 30,
                                             }}
                                           />



                                     
                                   )}
                                         </Box>
                                       }
                                     />
                                   )}
                                   
                                   {/* {(section == "tag" || section == "setting") && 
                                    <Chip
                                    onClick={() => setSection("tag")}
                                    sx={{
                                      backgroundColor: "#fff",
                                      border: `.5px solid #b3b3b3`,
                                      borderRadius: 1.5,
                                      height: 45
                                    }}
                                    label={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          gap:1
                                        }}
                                      >
                                        <Typography variant="caption" color="#4728c4">
                                          <LabelOutlined    sx={{ color: "#62676a", fontSize: 20 }} />
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 0.5,
                                          
                                          }}
                                        >
                                    {selectedTags.length > 0 ?
                                    
                                    selectedTags.slice(0,3).map((type) => (
                                      <Chip
                                        key={type}
                                        label={type}
                                        sx={{
                                          backgroundColor: "#f5f5f5",
                                          color: "#62676a",
                                          fontWeight: 500,
                                          fontSize: 12,
                                          height: 30,
                                          // border: "1px solid #aaa",
                                        }}
                                      />
                                    
                                    ) 
                                  )
                                : 
                                <Chip
                                        label={"No tags"}
                                        sx={{
                                          backgroundColor: "#f5f5f5",
                                          color: "#62676a",
                                          fontWeight: 500,
                                          fontSize: 12,
                                          height: 30,
                                          // border: "1px solid #aaa",
                                        }}
                                      />
                                }
                                        </Box>

                                        {selectedTags.length > 4 ? (
                                    <Typography
                                      variant="body2"
                                      color="#555"
                                      fontWeight={600}
                                    >
                                      +{selectedTags.length - 3}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}

                                      </Box>
                                    }
                                  /> 
         
                                    } */}

                                      
                             </Box>
         
        {/* <Box sx={{display:"flex", flexDirection:"row", mt:2}}>
          {section == "links" && (
            <>
            <Typography variant="h6" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
            {`Upload Post Links`}
            <br/>
            <Typography variant="body2" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          </Typography>
          </Typography>
          </>)}
          {section == "campaign" && (
            <>
       <Typography variant="h6" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
       {`Add (${numLinks}) Post${numLinks > 1 ? "s" : ""} to Campaigns`}  

          <br/>
          <Typography variant="body2" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          <Info sx={{height:16}}/> You can also do this later.
        </Typography>
        </Typography>
        </>)}

        {section == "tag" && (
            <>
       <Typography variant="h6" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          {`Add Content Tags to (${numLinks}) Post${numLinks > 1 ? "s" : ""}`}  
          <br/>
          <Typography variant="body2" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          <Info sx={{height:16}}/> You can also do this later.
        </Typography>
        </Typography>
        </>)}


        {section == "setting" && (
            <>
       <Typography variant="h6" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          {`Upload Settings`}  
          <br/>
          <Typography variant="body2" sx={{fontWeight:700, color: acceptPosts ? "#666" : "#bbb"}}>
          <Info sx={{height:16}}/> These settings will be applied to all posts in this upload.
        </Typography>
        </Typography>
        </>)}




        </Box> */}
    
        {/* <Typography variant="h6" sx={{fontWeight:700, color: acceptPosts ? "#333" : "#bbb"}}>
            {section == "links" ? "Add Posts" :
            `Add Post ${numLinks > 1 ? "s" : ""} to Campaign${selectedGroups.length > 1 ? "s": ""}`} 
       
        </Typography> */}

  
        </Box>


          {!isMobile && section == "links" && (
                <>
      <Box sx={{display:"flex", flexDirection:"column", gap:2}}>
                      
                      
                      <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1}}>
          <Typography variant="body1" color={acceptPosts ? "#333" : "#bbb"} fontWeight={600} >
            Upload Links by CSV or Excel<br/>
            <Typography variant="caption" color="#666">
              <InfoOutlined fontSize="small" /> Place all links in a single column.
              </Typography>
          </Typography>
            

          </Box>



              <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", alignItems:"center", gap:2, background:"#f5f5f5", borderRadius:2, p:3}}>
              <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", gap:1, width:"100%"}}>
              <FileUploader columnHeader={columnHeader} updateLinks={updateUploadedLinks} links={links} separator={separator} acceptPosts={acceptPosts}/>
              </Box>

              <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1, background:"#f5f5f5", borderRadius:2, width:"100%"}}>

            <Typography variant="body2" color="#333">
              Column header
              </Typography>

                <TextField
                size="small"
                // label="Column Header"
                disabled={!acceptPosts}
                  placeholder="Links"
                  value={columnHeader}
                  onChange={handleSetColumnHeader}
                  InputLabelProps={
                    {
                      shrink: true,
                      style: {
                        fontSize:14,
                        fontWeight:700
                      }
                    }
                  }
                  InputProps={{
                    style: {
                      fontSize:14,
                      fontWeight:700,
                      backgroundColor: "#fff",
                      border: "2px solid #ddd",
                      borderRadius: 8
                    },
                  }}
               
                  />
             
             </Box>
              </Box>

 
          </Box>

       

          </>
            )}
      
        
            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-stretch",
              flexGrow: 1,
              
            }}
          >  


        
            </Box>
  
  <Box sx={{display:"flex", flexDirection:"column", minHeight:150}}>

{section == "links" && (<>
        
 <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1}}>
            <Typography variant="body1" color={acceptPosts ? "#333" : "#bbb"} fontWeight={600}>
           Enter Links Manually
            <br/>
            <Typography variant="caption" color="#666">
            <InformationCircleIcon style={{height:16, display:"inline-block", marginRight:5}}/>
             Enter post links separated by LINE BREAKS OR COMMAS. Trailing parameters will be removed.
              </Typography>

          </Typography>
            <Box sx={{flexGrow:1}}/>
       
            </Box>

            <br/>
            <Box sx={{display:"flex", flexDirection:"column", gap:1, alignItems:"center", background:"#f5f5f5", borderRadius:2, p:2}}>
            <TextField
              
              InputProps={{
                style: {
                  fontSize:16,
                  border: "2px solid #eee",
                  borderRadius: 12,
                  backgroundColor: "#fff",
                },
              }}
              variant="outlined"
              disabled={!acceptPosts}
              multiline
              rows={numLinks < 4 ? 5 : numLinks + 2 }
              fullWidth
              placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              onChange={handleUpdateLinks}
              value={links.join(separator)}
              error={invalidLinks.length > 0 || existingLinks.length > 0}
              helperText={invalidLinks.length > 0 ? `Invalid link${invalidLinks.length > 1 ? "s" : ""}: ${invalidLinks.map((link) => link).join(", ")}` : 
              existingLinks.length > 0 ? `Post${existingLinks.length > 1 ? "s" : ""} already exist${existingLinks.length > 1 ? "" : "s"} in this team: ${existingLinks.map((link) => link).join(", ")}` : ""}
              
             
            
            />
<Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-start", width:"100%", gap:1}}>
<Typography variant="caption" color="#666">
              *Post Links ONLY from supported platforms: Instagram, TikTok, YouTube, X/Twitter, LinkedIn
              </Typography>
              </Box>

            </Box>
      </>
      )}

              {section === "campaign" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-stretch",
                    gap: 1,
                    background: "#f5f5f5",
                    p:2,
                    borderRadius: 2,
                  }}
                >
                  {teamData && teamData.groups && addToGroup ? (
                    <GroupAutoComplete
                      highlightGroupField={false}
                      selectedGroups={selectedGroups}
                      setSelectedGroups={setSelectedGroups}
                      disableGroupChange={null}
                      setDisableGroupChange={null}
                      teamData={teamData}
                      groupMatchType={groupMatchType}
                      setGroupMatchType={setGroupMatchType}
                      handleGroupChange={handleGroupChange}
                      softRefresh={softRefresh}
                    />
                  ) : null}
                </Box>
            
              )}
  
  {section == "tag" && (

<Box sx={{ display: "flex", width: "100%"}}>
  <Autocomplete
  freeSolo
    fullWidth
    sx={{
      width: "100%",
      cursor: "pointer",
      borderRadius: 2,
      color: "#999",
      fontWeight: 600,
      border: "1px solid #ddd",
      backgroundColor: "#fff",
      mt: 1.5,
      "& .MuiAutocomplete-inputRoot": {
        fontSize: "16px",
      },
      "& .MuiAutocomplete-option": {
        fontSize: "16px",
      },
    }}
    forcePopupIcon={true}
    disableCloseOnSelect
    clearIcon={
      selectedTags.length == 0 ? null : (
        <Clear />
      )
    }
    multiple
    options={
      //get all platforms in teamData.posts and remove duplicates
      teamData.posts
        .map((post) =>
          JSON.parse(post.data.tags)
        )
        .flat()
        .filter(
          (value, index, self) =>
            self.indexOf(value) === index
        )
    }
    // getOptionLabel={(option) => formatPlatform(option)}
    renderOption={(
      props,
      option,
      { selected }
    ) => {
      return (
        <li
          {...props}
          style={{
            pointerEvents: 
           //  disableGroupChange
           //    ? "none"
           //    :
               "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Checkbox
              checked={selected}
              size="small"
            />
            <Typography
              variant="body1"
              sx={{
                color: "#666",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {option}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            {/* {selected ?
<IconButton size="small" onClick={() => openAddNew("group", true)}><FolderCopy sx={{color:"#999"}}/></IconButton>
: null} */}
          </Box>
        </li>
      );
    }}
    onChange={(event, value) =>
      setSelectedTags(
        value.filter(
          (option) => option != "Any"
        )
      )
    }
    value={
      selectedTags.length > 0
        ? selectedTags
        : ["Any"]
    }
    // isOptionEqualToValue={(option, value) =>
    //   option.id == value.id
    // }

    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <>
          <Chip
            key={index}
            sx={{
              backgroundColor: "#fff",
              border: "2px solid #ddd",
            }}
            label={
              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {option}
              </Typography>
            }
            {...getTagProps({ index })}
            onDelete={
              option == "Any"
                ? null
                : () => {
                    let newValue =
                      selectedTags.filter(
                        (platform) =>
                          platform != option
                      );
                    setSelectedTags(
                      newValue
                    );
                  }
            }

            // deleteIcon={<CloseOutlined />}
          />
          {/* {value.length > 1 &&
          index < value.length - 1 ? (
            <Typography
              variant="body1"
              sx={{
                color: "#666",
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {typeMatchType.toUpperCase()}
            </Typography>
          ) : null} */}
        </>
      ))
    }
    renderInput={(params) => (
      <TextField
        InputProps={{
          sx: { overflow: "hidden" },
        }} // Adjust input text font size
        {...params}
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "16px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "16px",
          },
          "& .MuiAutocomplete-option": {
            fontSize: "16px",
          },
        }}
        variant="outlined"
        label={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#999",
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Tag
            </Typography>
            <FilterList
              sx={{
                color: "#666",
                height: 20,
                width: 20,
              }}
            />
          </Box>
        }
        placeholder=""
      />
    )}
  />

</Box>

)}

{section == "setting" && (
<Box sx={{display:"flex", flexDirection:"column", gap:2, width:"100%", mt:4}}>

<CommentSlider teamPlan={teamPlan}/>


  </Box>
)}


<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", mt:4, width:"100%"}}>

<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:2, background:"#f5f5f5", borderRadius:2, p:2, width:"fit-content"}}>
<InformationCircleIcon style={{height:24, display:"inline-block", marginRight:5}}/>

<Typography variant="body2" sx={{fontWeight:700, color: "#333"}}>
  Posts currently take from 10 minutes up to a few hours to process.  
</Typography>
</Box>

</Box>
                
</Box>

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", alignItems:"center", mt: 2, gap: 2, mb:2}}
          >
            {section == "campaign" && (
              <>
                <Button
              onClick={() => setSection("links")}
              variant="outlined"
              sx={{borderRadius: 8, padding: "8px 16px", textTransform:"none", fontWeight:600}}
              disabled={
              !acceptPosts || (numLinks > 0 && !linksValid) || numLinks == 0
              }
              startIcon={<ArrowBackIos />}
          
            >
            Back
            </Button>
            <Box sx={{flexGrow:1}}/>
            </>
            )}
                  {section == "setting" && (
              <>
                <Button
              onClick={() => setSection("campaign")}
              variant="outlined"
              sx={{borderRadius: 8, padding: "8px 16px", textTransform:"none", fontWeight:600}}
              disabled={
              !acceptPosts || (numLinks > 0 && !linksValid) || numLinks == 0
              }
              startIcon={<ArrowBackIos />}
          
            >
            Back
            </Button>
            <Box sx={{flexGrow:1}}/>
            </>
            )}

{section == "submit" && (
              <>
                <Button
              onClick={() => setSection("tag")}
              variant="outlined"
              sx={{borderRadius: 8, padding: "8px 16px", textTransform:"none", fontWeight:600}}
              disabled={
              !acceptPosts || (numLinks > 0 && !linksValid) || numLinks == 0
              }
              startIcon={<ArrowBackIos />}
          
            >
            Back
            </Button>
            <Box sx={{flexGrow:1}}/>
            </>
            )}
            {existingLinks && existingLinks.length > 0 && (
                <Tooltip title="Remove invalid entries">
                <Button sx={{textTransform:"none"}} size="small" onClick={() => removeExistingLinks()} variant="standard" endIcon={<DeleteForeverOutlined color="error" sx={{height:25, width:25,                }}/>}>
                <Typography variant="h6" fontWeight={500} color="error">
                  <b>{existingLinks.length}</b> existing entr{existingLinks.length > 1 ? "ies" : "y"}
                </Typography>
                </Button>
                </Tooltip>
            )}

            {invalidLinks && invalidLinks.length > 0 && (
                <Tooltip title="Remove invalid entries">
                <Button sx={{textTransform:"none"}} size="small" onClick={() => removeInvalidLinks()} variant="standard" endIcon={<DeleteForeverOutlined color="error" sx={{height:25, width:25,                }}/>}>
                <Typography variant="h6" fontWeight={500} color="error">
                  <b>{invalidLinks.length}</b> invalid entr{invalidLinks.length > 1 ? "ies" : "y"}
                </Typography>
                </Button>
                </Tooltip>
            )}
            {duplicateLinks && duplicateLinks.length > 0 && (
              <Tooltip title="Remove duplicate entries">
            <Button sx={{textTransform:"none"}} size="small" onClick={() => removeDuplicateLinks()} variant="standard" endIcon={<DeleteForeverOutlined sx={{height:25, width:25, fill:"#ff8227"
            }}/>}>

                <Typography variant="h6" fontWeight={500} color="#ff8227">
                  <b>{duplicateLinks.length}</b> duplicate entr{duplicateLinks.length > 1 ? "ies" : "y"}
                </Typography>
                </Button>
                </Tooltip>
                
         
            )} {links && numLinks > 0 && (
              <Typography variant="h6" fontWeight={500}>
              <b> {numLinks}</b> post{numLinks > 1 ? "s" : ""} 
            </Typography>
            )}
            {/* <Button onClick={handleClose}>Close</Button> */}
           
            {section == "links" && (
                <Button
              onClick={() => setSection("campaign")}
              variant="contained"
              sx={{borderRadius: 8, padding: "8px 16px", textTransform:"none", fontWeight:600}}
              disabled={numLinks == 0 ||
              !acceptPosts || (numLinks > 0 && !linksValid) 
              }
              endIcon={<ArrowForwardIos />}
          
            >
            Next
            </Button>
            )}
            {section == "campaign" && (
                <Button
              onClick={() => setSection("setting")}
              variant="contained"
              sx={{borderRadius: 8, padding: "8px 16px", textTransform:"none", fontWeight:600}}
              disabled={numLinks == 0 ||
              !acceptPosts || (numLinks > 0 && !linksValid) 
              }
              endIcon={<ArrowForwardIos />}
          
            >
            Next
            </Button>
            )}
              {/* {section == "tag" && (
                <Button
              onClick={() => setSection("submit")}
              variant="outlined"
              sx={{borderRadius: 8}}
              disabled={numLinks == 0 ||
              !acceptPosts || (numLinks > 0 && !linksValid) 
              }
              endIcon={<ArrowForwardIos />}
          
            >
            Next
            </Button>
            )} */}

               {section == "setting" && (
                <>
                {!confirm ?
                <Button
              onClick={() => setConfirm(true)}
              variant="contained"
              sx={{borderRadius: 8, fontSize:18, height:50, fontWeight:600}}
              disabled={
              !acceptPosts || (addToGroup && ( groupIds.length > 2 || (selectedGroups && selectedGroups.length == 1 && selectedGroups[0].type != "campaign"))) && (newCampaignName == "" && numLinks == 0 ) || (numLinks > 0 && !linksValid)
              }
              endIcon={<Add />}
          
            >
            {`Add ${numLinks} Post${numLinks > 1 ? "s" : ""}`}
            </Button>
            :
            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:2}}>

              <Button 
              onClick={() => setConfirm(false)}
              variant="outlined"
              color="error"
              sx={{borderRadius:8}}
              startIcon={<Close />}
              >
              Cancel
              </Button>




            <Button
            onClick={() => {
              handleLinkDrop();
              setTimeout(() => {
              handleModalClose();
              refreshData();
              setProcessingPostsOpen(true);
              }, 1000);
            }}
            variant="contained"
            color="warning"
            sx={{borderRadius: 8, fontSize:18, height:50, fontWeight:600}}
            disabled={
            !acceptPosts || (addToGroup && ( groupIds.length > 2 || (selectedGroups && selectedGroups.length == 1 && selectedGroups[0].type != "campaign"))) && (newCampaignName == "" && numLinks == 0 ) || (numLinks > 0 && !linksValid)
            }
            endIcon={<Add />}
        
          >
          {`Confirm: Add ${numLinks} Post${numLinks > 1 ? "s" : ""}`}
          </Button>
          </Box>
          }

            </> 
               )}
               
          </Box>

        </Box>
        
        </Box>
    
      </Modal>
    );
  }


  function isValidSocialMediaLink(link) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]{11}/;
    const youtubeShortRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/shorts\/|youtu\.be\/)[\w-]{11}/;
    const instagramPostRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/p\/[\w-]+/;
    const instagramReelRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/reels?\/[\w-]+/;
    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+\/video\/[\w-]+/;
    const tiktokShortRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/(v|t)\/[\w.-]+/;
    const tikTokVMShortRegex = /^(https?:\/\/)?(www\.)?vm\.tiktok\.com\/[\w.-]+/;
    const linkedinPostRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/posts\/[\w-]+/;
    //https://x.com/johndsaunders/status/1773338178239226365
    const xRegex = /^(https?:\/\/)?(www\.)?x\.com\/[\w-]+\/status\/[\w-]+/;
    const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/[\w-]+\/status\/[\w-]+/;

    //check if video id length is correct 
    //instagram post id is 11 characters
    //youtube video id is 11 characters
    //tiktok video id is 19 characters

    const youtubeIdLength = 11;
    const instagramIdLength = 11;
    const tiktokIdLength = 19;


  
    return (
      youtubeRegex.test(link) ||
      youtubeShortRegex.test(link) ||
      instagramPostRegex.test(link) ||
      instagramReelRegex.test(link) ||
      tiktokRegex.test(link) ||
      tiktokShortRegex.test(link) ||
      tikTokVMShortRegex.test(link) ||
      linkedinPostRegex.test(link) ||
      xRegex.test(link) ||
      twitterRegex.test(link)
    );


  }

  const UploadButton = muiStyled(Button)({
    // margin: '20px 0',
    borderRadius: 8,
  });
  
  const FileUploader = (props) => {
    const {updateLinks, links, columnHeader, separator, acceptPosts } = props;
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (fileExtension === 'csv') {
        handleCSVFile(file);
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        handleExcelFile(file);
      } else {
        alert('Unsupported file type. Please upload a CSV or Excel file.');
      }

      //remove file from input
      event.target.value = null;
    };


  
    const handleCSVFile = (file) => {
      Papa.parse(file, {
        header: false, // Treat all rows equally
        complete: (results) => {
          const header = columnHeader.trim();
          let columnIndex = null;
          let headerRowIndex = null;
    
          // Search for the header in all rows
          for (let rowIndex = 0; rowIndex < results.data.length; rowIndex++) {
            const row = results.data[rowIndex];
            columnIndex = row.findIndex((cell) => 
              cell.toString().trim() === header ||
              cell.toString().trim().toLowerCase() === header.toLowerCase() ||
              cell.toString().trim().toUpperCase() === header.toUpperCase()
            );
            if (columnIndex !== -1) {
              headerRowIndex = rowIndex;
              break; // Stop searching once the header is found
            }
          }
    
          if (columnIndex !== null && headerRowIndex !== null) {
            // Gather data from the found column in all subsequent rows
            const links = results.data.slice(headerRowIndex + 1).map((row) => {
              const cell = row[columnIndex];
              return cell ? cell.toString().trim() : null;
            }).filter(Boolean).join(separator);
    
            console.log(links);
            updateLinks(links);
          } else {
            console.error('Column header not found in the CSV file');
          }
        },
        error: (error) => {
          console.error('Error parsing CSV file:', error);
        },
      });
    };
  
    const handleExcelFile = (file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
        const header = columnHeader.trim();
        let linksIndex = -1;
        let headerRowIndex = null;
    
        // Search for the header in all rows
        for (let rowIndex = 0; rowIndex < json.length; rowIndex++) {
          const row = json[rowIndex];
          linksIndex = row.findIndex((cell) => 
            cell.toString().trim() === header ||
            cell.toString().trim().toLowerCase() === header.toLowerCase() ||
            cell.toString().trim().toUpperCase() === header.toUpperCase()
          );
          if (linksIndex !== -1) {
            headerRowIndex = rowIndex;
            break; // Stop searching once the header is found
          }
        }
    
        if (linksIndex === -1) {
          alert('No "' + header + '" column found in the Excel file.');
          return;
        }
    
        // Gather links as a comma-separated string from the found column in all subsequent rows
        const links = json.slice(headerRowIndex + 1).map((row) => {
          const cell = row[linksIndex];
          return cell ? cell.toString().trim() : null;
        }).filter(Boolean).join(separator);
    
        updateLinks(links);
      };
    
      reader.readAsArrayBuffer(file);
    };
  
    return (

          <UploadButton variant="contained" disabled={!acceptPosts || !columnHeader} component="label" startIcon={<UploadFileOutlined />}>
            CSV/XLSX
            <input type="file" hidden onChange={handleFileUpload} accept=".csv, .xls, .xlsx" />
          </UploadButton>

    );
  };

  function CommentSlider(props){

    const {teamPlan} = props;

    const marks = [
      { value: 250, label: '250' },
      { value: 2000, label: '500' },
      { value: 4000, label: '1000' },
      { value: 6000, label: '2500' },
      { value: 8000, label: '5000' },
      { value: 10000, label: 'Unlimited' }, // Using 10000 as a placeholder for "Unlimited"
    ];

    const getValueLabel = (value) => {
      const values = {
        250: 250,
        2000: 500,
        4000: 1000,
        6000: 2500,
        8000: 5000,
        10000: 10000,
      };

      return values[value];
      }

    const [value, setValue] = React.useState(250);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };  

    const limiterDisabled = !teamPlan || (teamPlan.name && (teamPlan.name.toLowerCase().includes("free") || teamPlan.name.toLowerCase().includes("trial")));
  
    return (
      <div>
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", gap:2, width:"100%"}}>
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1, width:"100%", justifyContent:"flex-start"}}>

              <Typography variant="body1" sx={{fontWeight:700, color:"#666"}}>
          Set Post Comment Limit
          <br/>
          <Typography variant="caption" sx={{fontWeight:500, color: "#666"}}>
          <InformationCircleIcon style={{height:16, display:"inline-block", marginRight:5}}/>

          The maximum number of comments imported per post
        </Typography>
        <br/>
        {limiterDisabled && (
          <Typography variant="caption" sx={{fontWeight:700, color: "#666"}}>
          <WarningOutlined sx={{height:16, color:"#ff8d94"}}/> Upgrade to increase
          </Typography>
        )}
        </Typography>
        </Box>

        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1, width:"100%", justifyContent:"flex-start"}}>
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:2, background:"#f5f5f5", borderRadius:2, p:3, width:"100%", maxWidth:500}}>
            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:1, background:"#fff", borderRadius:2, pb:2, pt:2, pr:4, pl:4, border:"2px solid #ddd", width:"100%"}}>
        <Slider
       disabled={limiterDisabled}
          value={value}
          onChange={handleChange}
          aria-labelledby="comment-limit-slider"
          step={null}
          marks={marks}
          min={250}
          max={10000}
          valueLabelDisplay="off"
          valueLabelFormat={getValueLabel}
          sx={{
            width:"100%",
            height: 8, // Increase the height of the slider to make it thicker
            '& .MuiSlider-thumb': {
              width: 20,
              height: 20,
              '&:before': {
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
              },
            },
            '& .MuiSlider-track': {
              border: 'none',
            },
            '& .MuiSlider-mark': {
              height: 8,
              width: 2,
              '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: 'currentColor',
              },
            },
            '& .MuiSlider-markLabel': {
              color: '#666',
              fontSize: 12,
              fontWeight: 500,
              '&.MuiSlider-markLabelActive': {
                opacity: 1,
                fontSize:16,
                fontWeight:700
              },
            },

          }}
        />
        </Box>
        </Box>
        </Box>
        </Box>
      </div>
    );
  }
